// @flow
import amplitude from 'amplitude-js';

import Util from 'utilities';

class Analytics {
  init() {
    if (Util.isProduction()) {
      amplitude.getInstance().init('4d7004180440b490e21857e64fae3282');
    }
  }

  identify(userId, email) {
    if (Util.isProduction()) {
      amplitude.getInstance().setUserId(userId);

      var identify = new amplitude.Identify().setOnce('email', email);
      amplitude.getInstance().identify(identify);
    }
  }

  logEvent(name) {
    if (Util.isProduction()) {
      amplitude.getInstance().logEvent(name);
    }
  }
}

export default new Analytics();
