import { connect } from 'react-redux';
import { isEmpty as _isEmpty } from 'lodash-es';
import { withRouter, Route } from 'react-router-dom';
import LoginRedirect from '../LoginRedirect';
import NotFound from 'components/route/NotFound';
import PropTypes from 'prop-types';
import React from 'react';
import Util from 'utilities';

const CreatorRoute = ({
  component: TheComponent,
  isCreator,
  isLoggedIn,
  render,
  userIdentified,
  ...props
}) => {
  return (
    <Route
      {...props}
      render={props => {
        if (!isLoggedIn) return <LoginRedirect {...props} />;
        if (!isCreator) return <NotFound {...props} />;
        return render ? render(props) : <TheComponent {...props} />;
      }}
    />
  );
};

const mapStateToProps = state => ({
  isCreator: Util.isCreator(state),
  isLoggedIn: Util.isLoggedIn(state),
  userIdentified: !_isEmpty(state?.auth?.me)
});

CreatorRoute.contextTypes = {
  component: PropTypes.object
};

export default withRouter(
  connect(
    mapStateToProps,
  )(CreatorRoute)
);
