import { Redirect, Route, withRouter } from 'react-router-dom';
import React from 'react';

const LoginRedirect = ({
  component: TheComponent,
  render,
  ...props
}) => {
  return (
    <Route
      {...props}
      render={() => {
        return <Redirect to={'/login'} />;
      }}
    />
  );
};

export default withRouter(LoginRedirect);
