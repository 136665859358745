import { connect } from 'react-redux';
import { fetchCurrentUser, fetchResourceList, logout } from 'actions';
import { get as _get, isEmpty as _isEmpty, values as _values } from 'lodash-es';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';
import Layout from './lib/Layout';
import React, { useCallback, useEffect } from 'react';
import Routes from './lib/Routes';
import ScrollToTop from './lib/ScrollToTop';
import Util from 'utilities';

import './styles.css';

function getDeviceType() {
  return window.matchMedia("(max-width: 480px)").matches ? 'PHONE' : 'DESKTOP';
}

const App = ({
  fetchCurrentUser,
  fetchProperties,
  isCreator,
  isLoggedIn,
  logout,
  setDeviceType,
}) => {

  const getUserAndProperties = useCallback(
    async () => {
      const userResponse = await fetchCurrentUser();
      const status = userResponse?.status || 200;
      if (status === 401) logout();
      if (isCreator) fetchProperties();
    },
    [
      fetchCurrentUser,
      fetchProperties,
      isCreator,
      logout,
    ]
  )

  useEffect(() => {
    setDeviceType(getDeviceType());
    getUserAndProperties();
  }, [
    getUserAndProperties,
    setDeviceType
  ]);

  useEffect(() => getUserAndProperties, [
    fetchCurrentUser,
    fetchProperties,
    getUserAndProperties,
    isCreator,
    isLoggedIn,
    logout
  ]);

  return (
    <ScrollToTop>
      <div id="Hello" className="App">
        <Helmet
          defaultTitle={process.env.REACT_APP_NAME}
          titleTemplate={`%s | ${process.env.REACT_APP_NAME}`}
        >
          <title>Hello</title>
          <meta property="og:title" content="Hello" />
          <meta property="og:type" content="website" />
        </Helmet>
        <Layout>
          <Routes />
        </Layout>
      </div>
    </ScrollToTop>
  )
};

const mapStateToProps = state => {
  return {
    alert: state.alert,
    breadcrumbItems: state.breadcrumb.items,
    isCreator: Util.isCreator(state),
    isLoggedIn: Util.isLoggedIn(state),
    propertiesArray: state.resources.properties ? _values(state.resources.properties) : [],
    isSponsor: Util.isSponsor(state),
    isStaff: Util.isStaff(state),
    userIdentified: !_isEmpty(_get(state, 'auth.me'))
  };
};

const mapDispatchToProps = dispatch => ({
  fetchCurrentUser: () => dispatch(fetchCurrentUser()),
  fetchProperties: () => dispatch(fetchResourceList('properties', { pageSize: 1000 })),
  logout: () => dispatch(logout()),
  setDeviceType: deviceType => dispatch({ type: 'SET_DEVICE_TYPE', value: deviceType })
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(App)
);
