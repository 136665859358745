import { connect } from 'react-redux';
import { get as _get, isEmpty as _isEmpty } from 'lodash-es';
import { withRouter, Route } from 'react-router-dom';
import LoginRedirect from '../LoginRedirect';
import NotFound from 'components/route/NotFound';
import PropTypes from 'prop-types';
import React from 'react';
import Util from 'utilities';

const StaffRoute = ({
  component: TheComponent,
  isLoggedIn,
  isStaff,
  render,
  userIdentified,
  ...props
}) => {
  return (
    <Route
      {...props}
      render={props => {
        if (!isLoggedIn) return <LoginRedirect {...props} />;
        if (!isStaff) return <NotFound {...props} />;
        return render ? render(props) : <TheComponent {...props} />;
      }}
    />
  );
};

const mapStateToProps = state => ({
  isLoggedIn: Util.isLoggedIn(state),
  isStaff: Util.isStaff(state),
  userIdentified: !_isEmpty(_get(state, 'auth.me'))
});

StaffRoute.contextTypes = {
  component: PropTypes.object
};

export default withRouter(
  connect(
    mapStateToProps,
  )(StaffRoute)
);
