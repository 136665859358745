import { forIn as _forIn } from 'lodash-es';

const routes = {
  Analytics: {
    Index: {
      path: '/analytics',
      title: 'Analytics'
    },
    PropertyPerformance: {
      path: '/analytics/:slug',
      title: 'Detail'
    },
  },
  Authorize: {
    Service: {
      path: '/authorize/:service',
      title: 'Authorize'
    }
  },
  Creators: {
    Index: {
      path: '/creators',
      title: 'Creators'
    },
    Detail: {
      path: '/creators/:id',
      title: 'Creator'
    }
  },
  Debits: {
    Index: {
      path: '/debits',
      title: 'Debits'
    },
    AddDebit: {
      path: '/debits/add',
      title: 'Add Debit'
    }
  },
  Financials: {
    Index: {
      path: '/financials',
      title: 'Financials'
    }
  },
  Homepage: {
    Index: {
      path: '/',
      title: ''
    }
  },
  InsertionOrder: {
    Detail: {
      path: '/:slug/:number',
      title: 'Order'
    },
    Index: {
      path: '/ios',
      title: 'Insertion Orders'
    }
  },
  Inventory: {
    Index: {
      path: '/inventory',
      title: 'Inventory'
    }
  },
  Invoices: {
    Index: {
      path: '/invoices',
      title: 'Invoices'
    },
    Detail: {
      path: '/invoices/:id',
      title: 'Invoice'
    }
  },
  Login: {
    Index: {
      path: '/login',
      title: 'Log in'
    }
  },
  Nebula: {
    Index: {
      path: '/nebula',
      title: 'Nebula Metrics'
    }
  },
  PasswordReset: {
    Index: {
      path: '/password_reset',
      title: 'Reset Password'
    },
    Done: {
      path: '/password_reset/done',
      title: 'Reset Password'
    }
  },
  Payments: {
    Index: {
      path: '/payments',
      title: 'Payments'
    }
  },
  Properties: {
    AddProperty: {
      path: '/properties/add',
      title: 'Add Property'
    },
    EditProperty: {
      path: '/properties/:id',
      title: 'Edit Property'
    }
  },
  Resources: {
    Index: {
      path: '/resources',
      title: 'Resources'
    }
  },
  Sales: {
    Index: {
      path: '/sales',
      title: 'Sales'
    },
    InsertionOrder: {
      path: '/sales/io/:id',
      title: 'IO'
    }
  },
  Schedule: {
    Index: {
      path: '/schedule',
      title: 'Schedule'
    },
    Detail: {
      path: '/schedule/:slug',
      title: 'Show'
    },
    Episode: {
      path: '/schedule/:slug/:id',
      title: 'Episode'
    }
  },
  Sponsors: {
    Index: {
      path: '/sponsors',
      title: 'Sponsors'
    },
    Detail: {
      path: '/sponsors/:slug',
      title: 'Sponsor'
    },
    AddSponsor: {
      path: '/sponsors/add',
      title: 'Add Sponsor'
    },
    Sponsorships: {
      path: '/sponsors/:slug/sponsorships',
      title: 'Sponsorships'
    },
    SponsorshipDetail: {
      path: '/sponsors/:slug/sponsorships/:id',
      title: 'Sponsorship'
    }
  },
  Sponsorships: {
    Index: {
      path: '/sponsorships',
      title: 'Sponsorships'
    }
  },
  Statements: {
    Index: {
      path: '/statements',
      title: 'Statements'
    },
    Detail: {
      path: '/statements/:id',
      title: 'Statement'
    }
  },
  Videos: {
    Index: {
      path: '/videos',
      title: 'Videos'
    },
    AddVideo: {
      path: '/videos/add',
      title: 'Add Video'
    }
  },
  Welcome: {
    Index: {
      path: '/welcome',
      title: 'Welcome'
    }
  },
  Privacy: {
    Index: {
      path: '/privacy',
      title: 'Privacy Policy'
    }
  }
};

export const apiRoutes = {
  InsertionOrder: {
    Download: {
      path: '/insertion_orders/:id/download/'
    },
    RequestSignature: {
      path: '/insertion_orders/:id/request_signature/'
    },
    SendToSponsor: {
      path: '/insertion_orders/:id/send_to_sponsor/'
    },
    DownloadCSV: {
      path: '/insertion_orders/:id/report/'
    }
  },
  OAuth: {
    Authorize: {
      path: '/oauth/:service/authorize/'
    },
    Redirect: {
      path: '/oauth/:service/redirect/'
    }
  }
};

export const makeRoute = (template, params, query, hash) => {
  let route = template;
  _forIn(params, (value, key) => (route = route.replace(`:${key}`, value)));
  _forIn(query, (value, key) => {
    route = route.includes('?') ? route + `&${key}=${encodeURIComponent(value)}` : route + `?${key}=${encodeURIComponent(value)}`;
  });
  route = hash ? `${route}#${hash}` : route;

  return route;
};

export default routes;
