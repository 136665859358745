import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

import View from './view.js';

class Alert extends Component {
  componentDidUpdate(prevProps) {
    const { clearAlert } = this.props;
    const oldAlert = prevProps.alert;
    const newAlert = this.props.alert;
    if (!this.timeout && newAlert !== oldAlert && !newAlert.persist) {
      this.timeout = setTimeout(() => {
        clearAlert();
      }, 4000);
    }
  }

  render() {
    return <View {...this.props} alert={this.props.alert} />;
  }
}

const mapStateToProps = state => {
  return {
    alert: state.alert, // type, message, persist
  };
};

const mapDispatchToProps = dispatch => {
  return {
    clearAlert: () => dispatch({ type: 'ALERT_CLEAR' })
  }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Alert));
