// @flow

import { CLEAR_AUTH, FETCH_CURRENT_USER_SUCCESS, SET_AUTH_TOKEN } from 'actions';

const reducer = (state = {}, action) => {
  switch (action.type) {
    case CLEAR_AUTH:
      return {};
    case FETCH_CURRENT_USER_SUCCESS:
      return Object.assign({}, state, {
        me: action.response
      });
    case SET_AUTH_TOKEN:
      return Object.assign({}, state, {
        token: action.token
      });
    default:
      return state;
  }
};

export default reducer;
