/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import color from 'color';
import posed, { PoseGroup } from 'react-pose';

import { ReactComponent as CheckSmallIcon } from 'icons/check_small.svg';
import { ReactComponent as WarningIcon } from 'icons/warning.svg';
import { ReactComponent as XIcon } from 'icons/delete.svg';

const Animator = posed.div({
  enter: {
    scale: 1,
    opacity: 1,
    transition: {
      scale: { type: 'spring', stiffness: 1000, damping: 25 },
      default: { duration: 300 }
    }
  },
  exit: {
    scale: 0,
    opacity: 0,
    transition: { duration: 200 },
  }
});

const Alert = ({ alert, clearAlert }) => {
  const type = alert.type;
  const isError = type === 'error';
  const isSuccess = type === 'success';

  const statusIcon = isError ? <WarningIcon css={{ fill: '#D02737', height: 24 }} /> : <CheckSmallIcon css={{ fill: '#fff', height: 22 }} />;

  const backgroundNeutral = color('#C5E4F5');
  const buttonNeutral = backgroundNeutral.darken(0.15);
  const buttonNeutralHover = buttonNeutral.darken(0.15);

  const backgroundGreen = color('#99F0E0');
  const buttonGreen = backgroundGreen.darken(0.15);
  const buttonGreenHover = buttonGreen.darken(0.15);

  const backgroundRed = color('#EAA3A1');
  const buttonRed = backgroundRed.darken(0.1);
  const buttonRedHover = buttonRed.darken(0.15);

  const backgroundColor = isSuccess ? backgroundGreen.string() : isError ? backgroundRed.string() : backgroundNeutral.string();
  const iconBackgroundColor = isSuccess ? '#00D9B1' : isError ? '#CD2832' : '#6FBCE6';
  const buttonColor = isSuccess ? buttonGreen.string() : isError ? buttonRed.string() : buttonNeutral.string();
  const buttonHoverColor = isSuccess ? buttonGreenHover.string() : isError ? buttonRedHover.string() : buttonNeutralHover.string();

  return (
    <div
      css={{
        position: 'absolute',
        bottom: 40,
        right: 40,
        zIndex: 1001,
        height: 40
      }}
    >
      <PoseGroup flipMove={false}>
        {
          type &&
            <Animator key='alert'>
              <div
                css={{
                  height: 40,
                  backgroundColor: backgroundColor,
                  borderRadius: 4,
                  display: 'grid',
                  gridTemplateColumns: '42px auto 44px',
                  gridTemplateRows: 'auto',
                }}
              >
                <div
                  css={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <div
                    css={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      borderRadius: 10,
                      width: 18,
                      height: 18,
                      backgroundColor: isError ? 'transparent' : iconBackgroundColor,
                      padding: 1
                    }}
                  >
                    { statusIcon }
                  </div>
                </div>
                <div
                  css={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontSize: 12.5,
                    padding: '0px 26px 0px 8px',
                  }}
                >
                  {alert.message}
                </div>
                <div
                  css={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: buttonColor,
                    borderTopRightRadius: 4,
                    borderBottomRightRadius: 4,
                    cursor: 'pointer',
                    '&:hover': {
                      backgroundColor: buttonHoverColor
                    }
                  }}
                  onClick={clearAlert}
                >
                  <XIcon css={{ fill: '#fff', height: 24 }} />
                </div>
              </div>
            </Animator>
        }
      </PoseGroup>
    </div>
  )
};

export default Alert;
