/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { Link } from 'react-router-dom';
import { colors } from 'consts';
import { toggleSidebar } from 'actions';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

const MenuItem = ({ to, icon, isPhone, isSidebarExpanded, location, text, toggleSidebar }) => {
  const currentPath = to.split(/(\?|#)/)[0];
  return (
    <Link
      to={to}
      css={{
        display: 'grid',
        gridTemplateColumns: '28px 1fr',
        gridTemplateRows: 'auto',
        gridTemplateAreas: '"icon text"',
        padding: '11.25px 29px',
        cursor: 'pointer',
        backgroundColor: location.pathname.includes(currentPath) ? colors.standard.blue : 'transparent',
        color: location.pathname.includes(currentPath) ? '#00141C' : '#788287',
        fill: location.pathname.includes(currentPath) ? '#00141C' : '#788287',
        transition: 'none',
        textDecoration: 'none',
        '&:hover': {
          color: '#fff',
          fill: '#fff',
        },
        '&:focus': {
          textDecoration: 'none',
        }
      }}
      onClick={isPhone ? toggleSidebar : () => null}
    >
      <div
        css={{
          gridArea: 'icon',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-start',
        }}
      >
        { icon }
      </div>
      <div
        css={{
          gridArea: 'text',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-start',
          letterSpacing: '0.75px',
          transition: 'transform ease-in-out 0.3s, opacity ease-in-out 0.3s',
          transform: (isPhone || isSidebarExpanded) ? 'translateX(0px)' : 'translateX(50px)',
          opacity: (isPhone || isSidebarExpanded) ? '1' : '0',
        }}
      >
        <div
          css={{
            color: 'inherit',
            '&:hover': {
              color: 'inherit'
            }
          }}
        >
          {text}
        </div>
      </div>
    </Link>
  );
};

const mapStateToProps = state => {
  return {
    isPhone: state.device.type === 'PHONE',
    isSidebarExpanded: state.ui.isSidebarExpanded,
  };
};

const mapDispatchToProps = dispatch => ({
  toggleSidebar: () => dispatch(toggleSidebar())
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MenuItem));
