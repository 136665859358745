/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import PropTypes from 'prop-types';

const UserNameAndIcon = ({ image, name, isLogoutShowing, isSidebarExpanded, showLogout, hideLogout, logout, toggleSidebar }) => {
  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        position: 'absolute',
        left: '0px',
        bottom: '0px',
        right: '0px',
        padding: '20px'
      }}
      onMouseOver={showLogout}
      onMouseOut={hideLogout}
    >
      <div
        css={{
          backgroundColor: 'rgba(255,255,255,0.075)',
          backgroundImage: `url(${image})`,
          backgroundPosition: 'center center',
          backgroundSize: 'cover',
          border: '1px solid #788287',
          borderRadius: '20px',
          cursor: 'pointer',
          height: '36px',
          marginRight: '14px',
          width: '36px',
        }}
        onClick={toggleSidebar}
      />
      <div
        css={{
          flex: '1',
          height: '36px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyContent: 'center',
          transition: 'all ease-in-out 0.3s',
          transform: isSidebarExpanded ? 'translateX(0px)' : 'translateX(50px)',
          opacity: isSidebarExpanded ? '1' : '0',
        }}
      >
        <div
          css={{
            color: '#fff',
          }}
        >
          { name }
        </div>
        <div
          css={{
            color: '#788287',
            fontSize: '13px',
            lineHeight: '13px',
            cursor: 'pointer',
            '&:hover': {
              color: '#fff',
            }
          }}
          onClick={logout}
        >
          Log Out
        </div>
      </div>
    </div>
  );
};

UserNameAndIcon.propTypes = {
  name: PropTypes.string,
  image: PropTypes.string
};

UserNameAndIcon.defaultProps = {
  name: 'Me',
  image: null
};

export default UserNameAndIcon;
