import { connect } from 'react-redux';
import { isEmpty as _isEmpty, values as _values } from 'lodash-es';
import { Redirect, Route, withRouter } from 'react-router-dom';
import LoginRedirect from '../LoginRedirect';
import PropTypes from 'prop-types';
import React from 'react';
import Util from 'utilities';

const HomeRoute = ({
  component: TheComponent,
  isCreator,
  isSponsor,
  isStaff,
  isLoggedIn,
  properties,
  render,
  userIdentified,
  ...props
}) => {
  return (
    <Route
      {...props}
      render={props => {
        if (!isLoggedIn) return <LoginRedirect {...props} />;
        if (!isSponsor && !isCreator && !isStaff) return null;
        if (isSponsor) return <Redirect to={'/sponsorships'} />;
        if (isCreator) {
          if (properties.length === 0) return null;
          if (properties.length === 1) return <Redirect to={`/schedule/${properties?.[0]?.attributes?.slug}#upcoming`} />;
        }
        return <Redirect to={'/schedule'} />
      }}
    />
  );
};

const mapStateToProps = state => ({
  isCreator: Util.isCreator(state),
  isLoggedIn: Util.isLoggedIn(state),
  isSponsor: Util.isSponsor(state),
  isStaff: Util.isStaff(state),
  properties: state.resources.properties ? _values(state.resources.properties) : [],
  userIdentified: !_isEmpty(state?.auth?.me)
});

HomeRoute.contextTypes = {
  component: PropTypes.object
};

export default withRouter(
  connect(
    mapStateToProps,
  )(HomeRoute)
);
