// @flow

import { SELECT_YOUTUBE_ACCOUNT, TOGGLE_SIDEBAR } from 'actions';

const savedIsSidebarExpanded = localStorage.getItem('isSidebarExpanded');
const defaultState = {
  isSidebarExpanded: savedIsSidebarExpanded ? JSON.parse(savedIsSidebarExpanded) : true
}

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case SELECT_YOUTUBE_ACCOUNT:
      return Object.assign({}, state, { selectedYoutubeAccount: action.id });
    case TOGGLE_SIDEBAR:
      localStorage.setItem('isSidebarExpanded', !state.isSidebarExpanded); // dirty, side-effectful hack - to be replaced by middleware
      return Object.assign({}, state, { isSidebarExpanded: !state.isSidebarExpanded });
    default:
      return state;
  }
};

export default reducer;
