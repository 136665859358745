import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { get as _get } from 'lodash-es';
import { logout, toggleSidebar } from 'actions';
import Utils from 'utilities';

import View from './view.js';

class UserNameAndIconContainer extends Component {
  constructor(props) {
    super(props);
    this.showLogout = this.showLogout.bind(this);
    this.hideLogout = this.hideLogout.bind(this);
    this.logout = this.logout.bind(this);
    this.toggleSidebar = this.toggleSidebar.bind(this);
    this.state = {
      isLogoutShowing: false
    }
  }
  showLogout() {
    this.setState({ isLogoutShowing: true })
  }
  hideLogout() {
    this.setState({ isLogoutShowing: false })
  }
  toggleSidebar() {
    this.props.toggleSidebar();
  }
  logout() {
    const { isPhone, isSidebarExpanded, toggleSidebar, logout } = this.props;
    if (isPhone && isSidebarExpanded) toggleSidebar();
    return logout();
  }
  render() {
    return (
      <View
        {...this.props}
        {...this.state}
        showLogout={this.showLogout}
        hideLogout={this.hideLogout}
        logout={this.logout}
        toggleSidebar={this.toggleSidebar}
      />
    );
  }
}

const mapStateToProps = state => ({
  image: _get(state, 'auth.me.attributes.image_url'),
  name: _get(state, 'auth.me.attributes.name'),
  isPhone: Utils.isPhone(state),
  isSidebarExpanded: _get(state, 'ui.isSidebarExpanded'),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  logout: () => dispatch(logout(ownProps.history)),
  toggleSidebar: () => dispatch(toggleSidebar())
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(UserNameAndIconContainer)
);
