// @flow
import axios from 'axios';
import { setupCache } from 'axios-cache-adapter';
import Util from 'utilities';
import { Deserializer } from 'jsonapi-serializer';
import { map as _map, mapKeys as _mapKeys, snakeCase as _snakeCase } from 'lodash-es';

export const config = {};
switch (window.location.hostname) {
  case 'localhost':
    config.API_HOST = 'http://localhost:3001';
    break;
  case 'staging.hello.standard.tv':
  case 'staging.hello.standard.tv.s3-website-us-east-1.amazonaws.com':
    config.API_HOST = 'https://api-staging.standard.tv';
    break;
  case 'beta.hello.standard.tv':
  case 'old.hello.standard.tv':
    config.API_HOST = 'https://api.standard.tv';
    break;
  default:
    config.API_HOST = 'https://api.standard.tv';
}

class Api {
  constructor() {
    this.client = axios.create({
      baseURL: config.API_HOST
    });
    this.cache = setupCache({
      maxAge: 15 * 60 * 1000,
      debug: false,
      exclude: {
        query: false
      },
      key: request => `${request.url}?${Util.url.serialize(request.params)}`
    });
    this.deserializer = new Deserializer({ keyForAttribute: 'snake_case' });
  }

  headers(token) {
    let headers = {
      'Content-Type': 'application/vnd.api+json'
    };

    if (token) {
      headers['Authorization'] = `Token ${token}`;
    }

    return headers;
  }

  get(url, token, params, args, skipCache = false) {
    return this.client(
      Object.assign(
        {},
        {
          headers: this.headers(token),
          method: 'GET',
          url,
          params
        },
        {
          adapter: skipCache ? null : this.cache.adapter,
          ...args
        }
      )
    );
  }

  async getAsync(args, { deserialize = true, responseType, skipCache, token }) {
    const baseArgs = {
      headers: this.headers(token),
      method: 'get',
    };
    if (!skipCache) baseArgs.adapter = this.cache.adapter;
    if (responseType) baseArgs.responseType = responseType;
    if (args.url) args.url = args.url.split('').pop() === '/' ? args.url : `${args.url}/`;
    if (args.params) {
      if (args.params.include) args.params.include = args.params.include.join(',');
      if (args.params.fields) {
        _map(args.params.fields, (value, key) => (args.params[`fields[${key}]`] = value.join(',')));
        delete(args.params.fields);
      }
      if (args.params.filters) {
        _map(args.params.filters, (value, key) => (args.params[`filter[${key}]`] = value));
        delete(args.params.filters);
      }
    }
    const argsWithMappedKeys = _mapKeys(args, (_, key) => _snakeCase(key));
    const request = Object.assign({}, baseArgs, argsWithMappedKeys);
    const response = await this.client(request);
    const meta = response.data.meta;
    let data = response.data;
    if (deserialize) { data = await this.deserializer.deserialize(response.data); }
    return {
      data,
      meta,
      raw: response
    };
  }

  post(url, token, params, data) {
    return this.client({
      headers: this.headers(token),
      method: 'post',
      url,
      params,
      data: { data }
    });
  }

  async postAsync(url, data, params, token) {
    const slashedUrl = url.split('').pop() === '/' ? url : `${url}/`;
    const response = await this.client({
      headers: this.headers(token),
      method: 'post',
      url: slashedUrl,
      params,
      data: { data }
    });
    const deserializedResponse = await this.deserializer.deserialize(response.data);
    return deserializedResponse;
  }

  // this is a hack to support application/json POSTs
  async postJSON(url, data, params, token) {
    const slashedUrl = url.split('').pop() === '/' ? url : `${url}/`;
    const response = await this.client({
      headers: Object.assign({}, this.headers(token), { 'Content-Type': 'application/json' }),
      method: 'post',
      url: slashedUrl,
      params,
      data
    });
    return response.data;
  }

  patch(url, token, data) {
    return this.client({
      headers: this.headers(token),
      method: 'PATCH',
      url,
      data: { data }
    });
  }

  async patchAsync(url, data, token) {
    const slashedUrl = url.split('').pop() === '/' ? url : `${url}/`;
    const response = await this.client({
      headers: this.headers(token),
      method: 'PATCH',
      url: slashedUrl,
      data: { data }
    });
    const deserializedResponse = await this.deserializer.deserialize(response.data);
    return deserializedResponse;
  }

  delete(url, token) {
    return this.client({
      headers: this.headers(token),
      method: 'DELETE',
      url
    });
  }

  postForm(url, token, form) {
    var params = new URLSearchParams();
    Object.keys(form).forEach(function(key) {
      params.append(key, form[key]);
    });

    const headers = Object.assign({}, this.headers(token), {
      'Content-Type': 'application/x-www-form-urlencoded'
    });

    return this.client({
      headers: headers,
      method: 'post',
      url,
      data: params
    });
  }

  postFile(url, token, file) {
    const formData = new FormData();
    formData.append('file', file);

    return this.client({
      headers: { ...this.headers(token), 'Content-Type': 'multipart/form-data' },
      method: 'POST',
      url,
      data: formData
    });
  }
}

export default new Api();
