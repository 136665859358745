import Cookie from 'js-cookie';

const AUTH_COOKIE_NAME = 'stv-auth';

class Auth {
  clearLoginCookie() {
    Cookie.remove(AUTH_COOKIE_NAME);
  }

  getLoginCookie() {
    return Cookie.get(AUTH_COOKIE_NAME);
  }

  setLoginCookie(token) {
    Cookie.set(AUTH_COOKIE_NAME, token, { expires: 365 });
  }
}

export default new Auth();
