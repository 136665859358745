/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { connect } from 'react-redux';

const DesktopNavHeader = ({ isSidebarExpanded }) => {
  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        padding: '23px 30px 0px'
      }}
    >
      <img
        src="https://hello.standard.tv/icons/production/transparent/96x96.png"
        alt="Standard Logo"
        css={{
          position: 'relative',
          top: '-1px',
          left: '2px',
          height: '15px',
          marginRight: '10px'
        }}
      />
      <div
        css={{
          color: '#fff',
          fontSize: '17px',
          transition: 'all ease-in-out 0.3s',
          transform: isSidebarExpanded ? 'translateX(0px)' : 'translateX(50px)',
          opacity: isSidebarExpanded ? '1' : '0',
        }}
      >
        Hello
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    isSidebarExpanded: state.ui.isSidebarExpanded,
  };
};

export default connect(mapStateToProps)(DesktopNavHeader);
