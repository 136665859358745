import { connect } from 'react-redux';
import { isEmpty as _isEmpty } from 'lodash-es';
import { withRouter, Route } from 'react-router-dom';
import LoginRedirect from '../LoginRedirect';
import NotFound from 'components/route/NotFound';
import PropTypes from 'prop-types';
import React from 'react';
import Util from 'utilities';

const StaffOrCreatorRoute = ({
  blockInactiveCreators = false,
  component: TheComponent,
  isCreator,
  isReadOnlyCreator,
  isLoggedIn,
  isStaff,
  render,
  userIdentified,
  ...props
}) => {
  return (
    <Route
      {...props}
      render={props => {
        if (!isLoggedIn) return <LoginRedirect {...props} />;
        if (!isStaff && !isCreator) return <NotFound {...props} />;
        if (blockInactiveCreators && isCreator && isReadOnlyCreator) return <NotFound {...props} />;
        return render ? render(props) : <TheComponent {...props} />;
      }}
    />
  );
};

const mapStateToProps = state => ({
  isCreator: Util.isCreator(state),
  isReadOnlyCreator: Util.isReadOnlyCreator(state),
  isLoggedIn: Util.isLoggedIn(state),
  isStaff: Util.isStaff(state),
  userIdentified: !_isEmpty(state?.auth?.me)
});

StaffOrCreatorRoute.contextTypes = {
  component: PropTypes.object
};

export default withRouter(
  connect(
    mapStateToProps,
  )(StaffOrCreatorRoute)
);
