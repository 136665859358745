/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { Link } from 'react-router-dom';
import Breadcrumb from 'components/shared/Breadcrumb';
import { ReactComponent as MenuOpenIcon } from 'icons/menu_open.svg';

const Header = ({ breadcrumbItems, isLoggedIn = false, isPhone, toggleSidebar }) => {
  return (
    <header
      style={{
        display: 'grid',
        gridTemplateColumns: '80px 1fr 80px',
        gridTemplateRows: 'auto',
        gridTemplateAreas: '"left center right"',
        position: 'absolute',
        top: 0,
        left: 0,
        transition: 'transform ease-in-out 0.5s',
        transitionDelay: isLoggedIn ? '0.7s' : '0s',
        transform: isLoggedIn ? 'translateY(0px)' : 'translateY(-80px)',
        width: '100%',
        height: '65px',
        backgroundColor: '#fff',
        zIndex: '10000',
        boxShadow: '0px 1px 5px rgba(0,0,0,0.1)',
        borderBottom: '1px solid #e6e6e6'
      }}
    >
      <div
        css={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          cursor: 'pointer',
        }}
        onClick={toggleSidebar}
      >
        <MenuOpenIcon
          css={{
            width: '32px',
            height: '65px',
          }}
        />
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        { isPhone &&
          <img
            src="https://hello.standard.tv/icons/production/transparent/96x96.png"
            alt="Standard Logo"
            style={{
              display: 'block',
              height: '22px'
            }}
          />
        }
        { !isPhone &&
          <Breadcrumb>
            {breadcrumbItems.map(({ path, label }) => (
              <Breadcrumb.Item key={path}>
                <Link
                  to={path}
                  style={{
                    fontSize: '13px',
                    fontWeight: '500',
                    color: '#00141E',
                    textTransform: 'uppercase'
                  }}
                >
                  {label}
                </Link>
              </Breadcrumb.Item>
            ))}
          </Breadcrumb>
        }
      </div>
      <div />
    </header>
  );
};

export default Header;
