/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { connect } from 'react-redux';
import { toggleSidebar } from 'actions';
import { ReactComponent as CloseIcon } from 'icons/close.svg';

const PhoneNavHeader = ({ isSidebarExpanded, toggleSidebar }) => {
  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: '70px 1fr 70px',
        gridTemplateRows: 'auto',
        gridTemplateAreas: '"left center right"',
        width: '100%',
        height: '65px',
      }}
    >
      <div
        css={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          cursor: 'pointer',
        }}
        onClick={toggleSidebar}
      >
        <CloseIcon
          css={{
            width: '50px',
            fill: '#fff',
            position: 'relative',
            left: 5,
          }}
        />
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <img
          src="https://hello.standard.tv/icons/production/transparent/96x96.png"
          alt="Standard Logo"
          css={{
            position: 'relative',
            top: '-1px',
            left: '2px',
            height: '15px',
            marginRight: '10px'
          }}
        />
        <div
          css={{
            color: '#fff',
            fontSize: '17px',
          }}
        >
          Hello
        </div>
      </div>
      <div />
    </div>
  )
}

const mapStateToProps = state => {
  return {
    isSidebarExpanded: state.ui.isSidebarExpanded,
  };
};

const mapDispatchToProps = dispatch => ({
  toggleSidebar: () => dispatch(toggleSidebar())
});

export default connect(mapStateToProps, mapDispatchToProps)(PhoneNavHeader);
