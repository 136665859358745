import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import Util from 'utilities';
import { toggleSidebar } from 'actions';

import View from './view.js';

class HeaderContainer extends Component {
  constructor(props) {
    super(props);
    this.toggleSidebar = this.toggleSidebar.bind(this);
  }
  toggleSidebar() {
    this.props.toggleSidebar();
  }
  render() {
    return (
      <View
        {...this.props}
        toggleSidebar={this.toggleSidebar}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    isLoggedIn: Util.isLoggedIn(state),
    isPhone: Util.isPhone(state),
    breadcrumbItems: state.breadcrumb.items
  };
};

const mapDispatchToProps = dispatch => ({
  toggleSidebar: () => dispatch(toggleSidebar())
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HeaderContainer));
