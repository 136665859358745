import { Route, Switch } from 'react-router-dom';
import { withRouter } from 'react-router';
import React, { Suspense } from 'react';

import CreatorRoute from '../CreatorRoute';
import HomeRoute from '../HomeRoute';
import PrivateRoute from '../PrivateRoute';
import OnlyUnauthRoute from '../OnlyUnauthRoute';
import SponsorRoute from '../SponsorRoute';
import StaffOrCreatorRoute from '../StaffOrCreatorRoute';
import StaffOrSponsorRoute from '../StaffOrSponsorRoute';
import StaffRoute from '../StaffRoute';

const Analytics = React.lazy(() => import('components/route/Analytics'));
const Authorize = React.lazy(() => import('components/route/Authorize'));
const Creators = React.lazy(() => import('components/route/Creators'));
const Creator = React.lazy(() => import('components/route/Creator'));
const DebitAdd = React.lazy(() => import('components/route/DebitAdd'));
const Debits = React.lazy(() => import('components/route/Debits'));
const EpisodeDetail = React.lazy(() => import('components/route/Episode'));
const Financials = React.lazy(() => import('components/route/Financials'));
const InsertionOrder = React.lazy(() => import('components/route/InsertionOrder'));
const InsertionOrderEdit = React.lazy(() => import('components/route/InsertionOrderEdit'));
const InsertionOrders = React.lazy(() => import('components/route/InsertionOrders'));
const Invoice = React.lazy(() => import('components/route/Invoice'));
const Invoices = React.lazy(() => import('components/route/Invoices'));
const Inventory = React.lazy(() => import('components/route/Inventory'));
const Login = React.lazy(() => import('components/route/Login'));
const Nebula = React.lazy(() => import('components/route/Nebula'));
const NotFound = React.lazy(() => import('components/route/NotFound'));
const PropertyAdd = React.lazy(() => import('components/route/PropertyAdd'));
const PropertyEdit = React.lazy(() => import('components/route/PropertyEdit'));
const PropertyPerformance = React.lazy(() => import('components/route/Analytics/routes/PropertyPerformance'));
const ResetPasswordComplete = React.lazy(() => import('components/route/ResetPasswordComplete'));
const ResetPasswordRequest = React.lazy(() => import('components/route/ResetPasswordRequest'));
const Resources = React.lazy(() => import('components/route/Resources'));
const Privacy = React.lazy(() => import('components/route/Privacy'));
const Properties = React.lazy(() => import('components/route/Properties'));
const Schedule = React.lazy(() => import('components/route/Schedule'));
const SponsorAdd = React.lazy(() => import('components/route/SponsorAdd'));
const Sponsor = React.lazy(() => import('components/route/Sponsor'));
const Sponsors = React.lazy(() => import('components/route/Sponsors'));
const Sponsorship = React.lazy(() => import('components/route/Sponsorship'));
const Sponsorships = React.lazy(() => import('components/route/Sponsorships'));
const Statements = React.lazy(() => import('components/route/Statements'));
const Statement = React.lazy(() => import('components/route/Statement'));
const Terms = React.lazy(() => import('components/route/Terms'));
const Videos = React.lazy(() => import('components/route/Videos'));
const VideoAdd = React.lazy(() => import('components/route/VideoAdd'));
const Welcome = React.lazy(() => import('components/route/Welcome'));

const Routes = () => {

  return (
    <Suspense fallback={null}>
      <Switch>

        {/* this route does a lot of work */}
        <HomeRoute path="/" exact />

        {/* accessible by both authenticated and unauthenticated users */}
        <Route path="/privacy" exact render={() => <Privacy/>} />
        <Route path="/password_reset" exact render={() => <ResetPasswordRequest/>} />
        <Route path="/password_reset/done" exact render={() => <ResetPasswordComplete/>} />
        <Route path="/terms" exact render={() => <Terms/>} />

        {/* accessible only by unauthenticated users */}
        <OnlyUnauthRoute path="/welcome" exact component={Welcome} />
        <OnlyUnauthRoute path="/login" exact component={Login} />

        {/* accessible only by staff */}
        <StaffRoute path="/creators" exact component={Creators} />
        <StaffRoute path="/creators/:id" exact component={Creator} />
        <StaffRoute path="/debits" exact component={Debits} />
        <StaffRoute path="/debits/add" exact component={DebitAdd} />
        <StaffRoute path="/financials" exact component={Financials} />
        <StaffRoute path="/nebula" exact component={Nebula} />
        <StaffRoute path="/properties/add" exact component={PropertyAdd} />
        <StaffRoute path="/properties/:id" exact component={PropertyEdit} />
        <StaffRoute path="/sales/io/:id" exact component={InsertionOrderEdit} />
        <StaffRoute path="/sponsors/add" exact component={SponsorAdd} />
        <StaffRoute path="/sponsors/:slug" exact component={Sponsor} />
        <StaffRoute path="/sponsors/:slug/sponsorships" exact component={Sponsorships} />
        <StaffRoute path="/sponsors/:slug/sponsorships/:id" exact component={Sponsorship} />

        {/* accessible only by creators */}
        <CreatorRoute path="/authorize/:service" exact component={Authorize} />

        {/* accessible only by staff OR creators */}
        <StaffOrCreatorRoute path="/analytics/:slug" exact component={PropertyPerformance} />
        <StaffOrCreatorRoute path="/resources" exact component={Resources} blockInactiveCreators={true} />
        <StaffOrCreatorRoute path="/schedule" exact component={Properties} />
        <StaffOrCreatorRoute path="/schedule/:slug" exact component={Schedule} />
        <StaffOrCreatorRoute path="/schedule/:slug/:id" exact component={EpisodeDetail} />
        <StaffOrCreatorRoute path="/sponsors" exact component={Sponsors} blockInactiveCreators={true} />
        <StaffOrCreatorRoute path="/statements" exact component={Statements} />
        <StaffOrCreatorRoute path="/statements/:id" exact component={Statement} />
        <StaffOrCreatorRoute path="/videos" exact component={Videos} />
        <StaffOrCreatorRoute path="/videos/add" exact component={VideoAdd} />

        {/* accessible only by sponsors */}
        <SponsorRoute path="/sponsorships" exact component={Sponsorships} />
        <SponsorRoute path="/ios" exact component={InsertionOrders} />

        {/* accessible only by staff OR sponsors */}
        <StaffOrSponsorRoute path="/sales" exact component={InsertionOrders} />

        {/* accessible only by authenticated users */}
        <PrivateRoute path="/analytics" exact component={Analytics} />
        <PrivateRoute path="/inventory" exact component={Inventory} />
        <PrivateRoute path="/invoices" exact component={Invoices} />
        <PrivateRoute path="/invoices/:id" exact component={Invoice} />

        {/* last because wildcards */}
        <StaffOrSponsorRoute path="/:slug/:number" exact component={InsertionOrder} />

        {/* if nothing matches the wildcards, show not found */}
        <Route render={() => <NotFound/>} />


      </Switch>
    </Suspense>
  );
};

export default withRouter(Routes);
