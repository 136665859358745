import { compact as _compact, get as _get, map as _map, snakeCase as _snakeCase } from 'lodash-es';

import Api from './api';
import Auth from './auth';
import Util from 'utilities';
import Analytics from 'analytics';

export const ALERT_CLEAR = 'ALERT_CLEAR';
export const ALERT_FAILURE = 'ALERT_FAILURE';
export const ALERT_SUCCESS = 'ALERT_SUCCESS';
export const CLEAR_AUTH = 'CLEAR_AUTH';
export const CLEAR_RESOURCES = 'CLEAR_RESOURCES';
export const DELETE_RESOURCE_DETAIL_SUCCESS = 'DELETE_RESOURCE_DETAIL_SUCCESS';
export const FETCH_CURRENT_USER_SUCCESS = 'FETCH_CURRENT_USER_SUCCESS';
export const FETCH_RESOURCE_DETAIL_SUCCESS = 'FETCH_RESOURCE_DETAIL_SUCCESS';
export const FETCH_RESOURCE_LIST_SUCCESS = 'FETCH_RESOURCE_LIST_SUCCESS';
export const SELECT_YOUTUBE_ACCOUNT = 'SELECT_YOUTUBE_ACCOUNT';
export const SET_AUTH_TOKEN = 'SET_AUTH_TOKEN';
export const SET_BREADCRUMBS = 'SET_BREADCRUMBS';
export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';

export const alertClear = () => ({
  type: ALERT_CLEAR
});

export const alertFailure = (message, persist = true) => dispatch => {
  dispatch({
    type: ALERT_FAILURE,
    message: message,
    persist: persist
  });
};

export const alertSuccess = message => dispatch => {
  dispatch({
    type: ALERT_SUCCESS,
    message: message,
    persist: false
  });
};

export const clearAuth = () => ({ type: CLEAR_AUTH });

export const clearResources = resourceType => {
  return resourceType ? { type: CLEAR_RESOURCES, resourceType } : { type: CLEAR_RESOURCES };
};

export const completePasswordReset = (uid, token, password, history) => (dispatch, getState) => {
  const data = {
    new_password: password,
    re_new_password: password,
    token: token,
    uid: uid
  };

  return Api.postForm('/auth/password/reset/confirm/', _get(getState(), 'auth.token'), data)
    .then(response => {
      // Log them in
      const email = _get(response, 'data.data.attributes.email');
      dispatch(login(email, password, history));
    })
    .catch(error => {
      dispatch(alertFailure('There was a problem setting your new password'));
    });
};

export const selectYoutubeAccount = id => ({
  type: SELECT_YOUTUBE_ACCOUNT,
  id
});

export const setBreadcrumbs = items => {
  const pageTitle = _get(items, `[${items.length - 1}].label`);
  document.title = _compact(['Hello', pageTitle]).join(' - ');

  return {
    type: SET_BREADCRUMBS,
    items
  };
};

export const fetchResourceListSuccess = (resourceType, response) => ({
  type: FETCH_RESOURCE_LIST_SUCCESS,
  resourceType,
  response
});

export const fetchResourceDetailSuccess = (resourceType, response, mergeProperties) => ({
  type: FETCH_RESOURCE_DETAIL_SUCCESS,
  resourceType,
  response,
  mergeProperties
});

export const deleteResourceDetailSuccess = (resourceType, id) => ({
  type: DELETE_RESOURCE_DETAIL_SUCCESS,
  resourceType,
  id
});

export const fetchCurrentUserSuccess = response => ({
  type: FETCH_CURRENT_USER_SUCCESS,
  response
});

export const fetchResourceList = (resourceType, { action, fields = {}, filters = {}, include, page, path, pageSize, prefix, skipCache = false, sort } = {}) => (dispatch, getState) => {
  const data = {};
  if (include) data.include = include.join(',');
  if (page) data.page = page;
  if (pageSize) data.page_size = pageSize;
  if (sort) data.sort = sort;

  _map(filters, (value, key) => (data[`filter[${key}]`] = value));

  _map(fields, (value, key) => (data[`fields[${key}]`] = value.join(',')));

  const requestPath = path || _compact([prefix, resourceType, action]).join('/');

  return Api.get(requestPath + '/', _get(getState(), 'auth.token'), data, null, skipCache).then(response => {
    dispatch(fetchResourceListSuccess(resourceType, response.data));

    return response.data;
  });
};

export const fetchResourceDetail = (resourceType, id, { include, action, mergeProperties, prefix, skipCache = false } = {}) => (dispatch, getState) => {
  const data = {};
  if (include) data.include = include.join(',');

  const path = _compact([prefix, resourceType, id, action]).join('/');

  return Api.get(path + '/', _get(getState(), 'auth.token'), data, null, skipCache).then(response => {
    dispatch(fetchResourceDetailSuccess(resourceType, response.data, mergeProperties));

    return response.data;
  });
};

export const createResource = (resourceType, attributes, options = {}) => createResourceHelper(resourceType, attributes, Object.assign({}, { successAction: fetchResourceDetailSuccess }, options));

export const createResourceList = (resourceType, attributes, options = {}) => createResourceHelper(resourceType, attributes, Object.assign({}, options, { successAction: fetchResourceListSuccess }));

const createResourceHelper = (resourceType, attributes, { action, id, include, relationships, successAction } = {}) => (dispatch, getState) => {
  const data = {
    type: resourceType,
    attributes,
    relationships
  };

  const params = {};
  if (include) params.include = include.join(',');

  const pluralizedKey = Util.pluralizeKey(_snakeCase(resourceType));
  const path = _compact([pluralizedKey, id, action]).join('/') + '/';

  return Api.post(path, _get(getState(), 'auth.token'), params, data).then(response => {
    dispatch(successAction(pluralizedKey, response.data));

    return response.data;
  });
};

export const editResource = (resourceType, id, attributes, { relationships, action } = {}) => (dispatch, getState) => {
  const data = {
    id,
    type: resourceType,
    attributes,
    relationships
  };

  const pluralizedKey = Util.pluralizeKey(_snakeCase(resourceType));
  const path = _compact([pluralizedKey, id, action]).join('/') + '/';

  return Api.patch(path, _get(getState(), 'auth.token'), data).then(response => {
    dispatch(fetchResourceDetailSuccess(pluralizedKey, response.data));

    return response.data;
  });
};

export const deleteResource = (resourceType, id) => (dispatch, getState) => {
  const pluralizedKey = Util.pluralizeKey(_snakeCase(resourceType));

  return Api.delete(`${pluralizedKey}/${id}/`, _get(getState(), 'auth.token')).then(response => {
    dispatch(deleteResourceDetailSuccess(pluralizedKey, id));

    return response.data;
  });
};

export const fetchCurrentUser = () => (dispatch, getState) => {
  dispatch({
    type: 'FETCHING_CURRENT_USER'
  });
  return dispatch(
    fetchResourceDetail('accounts', 'me', {
      include: ['auth_user', 'youtube_access_tokens']
    })
  )
    .then(response => {
      // Set the auth user onto the user for convienence
      const authUserId = _get(response.data, 'relationships.auth_user.data.id');
      if (authUserId !== undefined) {
        response.data.auth_user = _get(getState().resources.auth_users, `[${authUserId}]`);
      }

      Analytics.identify(response.data.id, response.data.attributes.email);

      dispatch(fetchCurrentUserSuccess(response.data));

      return response.data;
    })
    .catch(e => {
      return e;
    });
};

export const login = (email, password, history, returnUrl) => (dispatch, getState) => {
  const data = {
    username: email,
    password: password
  };

  return Api.postForm('/auth/login/', _get(getState(), 'auth.token'), data)
    .then(response => {
      const token = _get(response, 'data.data.attributes.auth_token');

      Auth.setLoginCookie(token);
      dispatch(setAuthToken(token));

      history.push(returnUrl);
    })
    .catch(error => {
      dispatch(alertFailure("We weren't able to log you in, check your email and password."));
    });
};

export const logout = history => (dispatch, getState) => {
  Api.cache.store.clear();
  Auth.clearLoginCookie();
  dispatch(clearAuth());
  dispatch(clearResources());
};

export const requestPasswordReset = email => (dispatch, getState) => {
  const data = { email: email };

  return Api.postForm('/auth/password/reset/', _get(getState(), 'auth.token'), data)
    .then(response => {
      dispatch(alertSuccess('Great! Check your email for a message with instructions on setting your password.'));
    })
    .catch(error => {
      dispatch(alertFailure('Enter a valid email address'));
    });
};

export const setAuthToken = token => ({
  type: SET_AUTH_TOKEN,
  token
});

export const toggleSidebar = () => ({
  type: TOGGLE_SIDEBAR
});

// export const publishStatements = id => createResourceHelper(resourceType, attributes, Object.assign({}, options, { successAction: fetchResourceDetailSuccess }));
